import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import ScoreHeading from "../../score-heading";
import { parse } from "../../../utils/component";
import { TideItemForEvenementLocatie, TideItemForFacility } from "../../../types";
import { find, first, split, sumBy, uniqBy } from "lodash";
import Carousel from "nuka-carousel";
import moment from "moment";
import { BookingPackageHotel, BookingPackageHotelOption } from "@qite/tide-client/build/types";
import { getPlaceHolderImage } from "../../../utils/image-utils";

interface AccommodationCardProps {
  hotel?: BookingPackageHotel;
  facilities: TideItemForFacility[];
  venue?: TideItemForEvenementLocatie;
}

const AccommodationCard: React.FC<AccommodationCardProps> = ({ hotel, facilities, venue }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingPackage, selectHotel, flyInIsOpen, setFlyInIsOpen, setFlyInHotel, setFlyInFacilities, requestRooms } =
    useContext<BookingContextType>(BookingContext);
  const numberOfPax = requestRooms.flatMap((r) => r.pax).length;
  const selectedHotel = bookingPackage?.options.find((o) => o.isSelected)?.hotels.find((h) => h.isSelected);
  const accoName = first(find(hotel?.rooms, { options: [{ isSelected: true }] })?.options.filter((o) => o.isSelected))?.accommodationName;
  const regimeName = first(find(hotel?.rooms, { options: [{ isSelected: true }] })?.options.filter((o) => o.isSelected))?.regimeName;

  const parseImages = (imageString: string) => {
    const images = split(imageString, "%image%");
    return images;
  };

  const handleSetFlyInHotel = (hotel: BookingPackageHotel) => {
    if (hotel?.facilities) {
      const facilityCodes = split(hotel.facilities, "/");
      const response = facilities.filter(
        (facility) => facility.content?.general?.hotelbedscode && facilityCodes.includes(facility.content?.general?.hotelbedscode)
      );
      setFlyInFacilities(uniqBy(response, "content.general.title"));
    }

    setFlyInHotel(hotel.name);
  };

  const parseDistance = (distance: number) => {
    const distanceInKm = distance / 1000;
    return distanceInKm.toFixed(1);
  };

  const parseTimeSpan = (timespan: string) => {
    const parsedTimeSpan = moment.duration(timespan);
    return (parsedTimeSpan.hours() > 0 ? parsedTimeSpan.hours() + "h" : "") + parsedTimeSpan.minutes() + " " + translate(translations, language, "MINUTES");
  };

  const getBottomControls = (images: any[], currentSlide: any, goToSlide: any) => {
    return (
      <div className="carousel__indicators">
        {images &&
          images.map((image, i) => (
            <button
              key={i}
              type="button"
              className={`carousel__indicator ${currentSlide === i ? "carousel__indicator--active" : ""}`}
              onClick={() => goToSlide(i)}
            ></button>
          ))}
      </div>
    );
  };

  const handleSelectHotel = (hotel: BookingPackageHotel) => {
    setFlyInIsOpen(!flyInIsOpen);
    handleSetFlyInHotel(hotel);
    selectHotel(hotel);
  };

  const calcPriceDifference = (hotel: BookingPackageHotel) => {
    const priceHotelInPackage = sumBy(
      selectedHotel?.rooms.flatMap((r) => r.options.filter((ro) => ro.isSelected)),
      "price"
    );
    return hotel.cheapestPrice - priceHotelInPackage;
  };

  const fetchRegimes = () => {
    if (hotel) {
      const options = hotel.rooms.flatMap((r) => r.options.filter((o) => o.isSelected));
      let allRegimeOptions: BookingPackageHotelOption[] = [];
      options.forEach((option) => {
        const regimeOptions = uniqBy(
          hotel.rooms.flatMap((hr) => hr.options.filter((o) => o.accommodationCode == option.accommodationCode && o.roomIndex == option.roomIndex)),
          "regimeCode"
        );
        allRegimeOptions = allRegimeOptions.concat(regimeOptions);
      });
      return uniqBy(allRegimeOptions, "regimeCode").map((r) => r.regimeName);
    }
  };

  return (
    <>
      {hotel && (
        <div className={`accommodations__card ${selectedHotel == hotel ? "accommodations__card--selected" : ""}`}>
          <div className="accommodations__card-body">
            {parseImages(hotel.imageUrl).length > 1 ? (
              <Carousel
                slidesToShow={1}
                renderBottomCenterControls={({ currentSlide, goToSlide }) => getBottomControls(parseImages(hotel.imageUrl), currentSlide, goToSlide)}
                defaultControlsConfig={{
                  nextButtonClassName: "carousel__control carousel__control--next",
                  nextButtonText: " ",
                  prevButtonClassName: "carousel__control carousel__control--prev",
                  prevButtonText: " ",
                }}
              >
                {parseImages(hotel.imageUrl).map((image, i) => (
                  <img
                    key={i}
                    src={image}
                    alt="Hotel"
                    onError={({ currentTarget }) => {
                      currentTarget.src = currentTarget.src.includes("giata/original")
                        ? getPlaceHolderImage()
                        : image.replace("giata/bigger", "giata/original").replace("giata/xl", "giata/original");
                    }}
                  />
                ))}
              </Carousel>
            ) : (
              <img
                src={first(parseImages(hotel.imageUrl))}
                alt={hotel.name}
                className="accommodations__image"
                onError={({ currentTarget }) => {
                  currentTarget.src = currentTarget.src.includes("giata/original")
                    ? getPlaceHolderImage()
                    : first(parseImages(hotel.imageUrl))
                      ? first(parseImages(hotel.imageUrl))!.replace("giata/xl", "giata/original").replace("giata/bigger", "giata/original")
                      : getPlaceHolderImage();
                }}
              />
            )}
            <div className="accommodations__columns">
              <div className="accommodations__columns-header">
                <ScoreHeading hotel={hotel} moreInfoTitle={translate(translations, language, "EDIT")} onHandleSetFlyInHotel={handleSetFlyInHotel} />
              </div>

              <div className="accommodations__columns-body">
                {/*
                {getFacilities(hotel.facilities).length > 1 && (
                  <div className="accommodations__column">
                    <div className="fontawesome-list">
                      <ul>
                        {getFacilities(hotel.facilities).map((facility, i) => (
                          <li key={i}>
                            <i className={parse(facility.content?.general?.iconFontAwesome)}></i>
                            {facility.content?.general?.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                */}
                <div className="accommodations__column">
                  <div className="accommodations__column--icon">
                    <i className="fa fa-bed"></i>
                  </div>
                  <div className="accommodations__column--wrapper">
                    <p>{accoName}</p>
                    {hotel.rooms.some(item => item.options && item.options.length > 1) && (
                      <button
                        type="button"
                        className="accommodations__more-info"
                        onClick={() => {
                          setFlyInIsOpen(!flyInIsOpen);
                          handleSetFlyInHotel(hotel);
                        }}
                      >
                        {translate(translations, language, "EDIT")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="accommodations__column">
                  <div className="accommodations__column--icon">
                    <i className="fa fa-utensils"></i>
                  </div>
                  <div className="accommodations__column--wrapper">
                    <p>{regimeName}</p>
                    {fetchRegimes()?.length && fetchRegimes()?.length! > 1 && (
                      <button
                        type="button"
                        className="accommodations__more-info"
                        onClick={() => {
                          setFlyInIsOpen(!flyInIsOpen);
                          handleSetFlyInHotel(hotel);
                        }}
                      >
                        {translate(translations, language, "EDIT")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="accommodations__column">
                  <div className="accommodations__column--icon">
                    <i className="fa fa-route"></i>
                  </div>
                  <span className="accommodations__column-properties">
                    {translate(translations, language, "KM_TO_CENTER", { distance: parseDistance(hotel.distanceToCenter) })}
                    <br />
                    {translate(translations, language, (process.env.WEBSITE === "Sportreizen" && venue) ? "KM_TO_VENUE" : "KM_TO_STADIUM", { distance: parseDistance(hotel.distanceToVenue), venue: venue?.content?.general?.title })}
                  </span>
                </div>
                <div className="accommodations__column">
                  <div className="accommodations__column--icon">
                    <i className="fa fa-bus-alt"></i>
                  </div>
                  <span className="accommodations__column-properties">
                    {translate(translations, language, "TIME_TO_CENTER", { time: parseTimeSpan(hotel.travelDurationToCenter) })}
                    <br />
                    {translate(translations, language, (process.env.WEBSITE === "Sportreizen") ? "TIME_TO_VENUE" : "TIME_TO_STADIUM", { time: parseTimeSpan(hotel.travelDurationToVenue), venue: venue?.content?.general?.title })}
                  </span>
                </div>
              </div>

              <div className="accommodations__columns-footer">
                {/*
                <button
                  type="button"
                  className="accommodations__more-info"
                  onClick={() => {
                    setFlyInIsOpen(!flyInIsOpen);
                    handleSetFlyInHotel(hotel);
                  }}
                  title={translate(translations, language, "SELECT_ROOM_AND_MEAL")}
                >
                  {translate(translations, language, "SELECT_ROOM_AND_MEAL")}
                </button>
                */}
                <div className="pricing">
                  {calcPriceDifference(hotel) != 0 ? (
                    <>
                      {calcPriceDifference(hotel) > 0 ? <>+</> : <>-</>}
                      <span className="pricing__price">
                        &euro; &nbsp;
                        {((calcPriceDifference(hotel) < 0 ? calcPriceDifference(hotel) * -1 : calcPriceDifference(hotel)) / numberOfPax).toFixed(2)}
                      </span>
                      p.p.
                    </>
                  ) : (
                    translate(translations, language, "NO_EXTRA_COST")
                  )}
                </div>
                {selectedHotel == hotel && (
                  <button
                    disabled
                    type="button"
                    className="cta cta--selected accommodations__cta"
                    title={translate(translations, language, "SELECTED")}
                  >
                    {translate(translations, language, "SELECTED")}
                  </button>
                )}
                {selectedHotel != hotel && (
                  <button type="button" className="cta accommodations__cta" title="Selecteer kamers" onClick={() => handleSelectHotel(hotel)}>
                    {translate(translations, language, "SELECT")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccommodationCard;
