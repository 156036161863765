import React, { useContext, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import { Image, Maybe, TideItemForCategory, TideItemForEvenementLocatie, TideItemForExcursion } from "../../types";
import { getChildItemsWithTypename, getSpecificChildItemWithName, parse } from "../../utils/component";
import { first, uniqBy } from "lodash";
import Icon from "../icon";
import { usePopperTooltip } from "react-popper-tooltip";
import Carousel from "nuka-carousel";
import TicketsUsps from "../tickets-usps";
import { addWidthParameter } from "../../utils/image-utils";

interface TicketsExtrasProps {
  venue?: TideItemForEvenementLocatie;
  excursions: TideItemForExcursion[];
}

const cacheTresholdGroupName = "CACHE TRESHOLD";

const TicketsExtras: React.FC<TicketsExtrasProps> = ({ venue, excursions }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingPackage, selectExtras, requestRooms } = useContext<BookingContextType>(BookingContext);
  // all groups without the 'CACHE TRESHOLD' group for now
  const extras = uniqBy(
    bookingPackage?.options
      .find((o) => o.isSelected)
      ?.groups.filter((g) => g.name !== cacheTresholdGroupName)
      .flatMap((g) => g.options),
    "line.productName"
  );

  const numberOfPax = requestRooms.flatMap((r) => r.pax).length;

  // Popper
  const [hovered, setHovered] = useState("");
  const [controlledVisible, setControlledVisible] = useState(false);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: null,
    placement: "top",
    interactive: true,
    offset: [5, 10],
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  });

  const handleTooltipTrigger = (productCode: string) => {
    setHovered(productCode);
    setControlledVisible(true);
  };
  // End Popper

  const selectedTicket = first(bookingPackage?.options.find((o) => o.isSelected)?.rooms)?.options.find((o) => o.isSelected);
  const categories = getChildItemsWithTypename("TideItemForCategory", venue) as TideItemForCategory[];

  let category = selectedTicket && categories?.find((x) => x.content?.general?.id == selectedTicket.externalAccommodationCode);
  if (!category && selectedTicket && (!selectedTicket.externalAccommodationCode || selectedTicket.externalAccommodationCode == "")) {
    category = getSpecificChildItemWithName(selectedTicket?.accommodationName, venue) as TideItemForCategory;
  }

  const findExcursionDescription = (productCode: string) => {
    const cmsData = excursions.find((e) => e.content?.general?.productlink?.code == productCode);
    if (cmsData) {
      return cmsData.content?.general?.description;
    }
    return "";
  };

  const getBottomControls = (images: Maybe<Maybe<Image>[]>, currentSlide: any, goToSlide: any) => {
    return (
      <div className="carousel__indicators">
        {images &&
          images.map((image, i) => (
            <button
              key={i}
              type="button"
              className={`carousel__indicator ${currentSlide === i ? "carousel__indicator--active" : ""}`}
              onClick={() => goToSlide(i)}
            ></button>
          ))}
      </div>
    );
  };

  return (
    <div className="tickets-extras">
      <TicketsUsps venue={venue?.content?.general?.title ?? ""}></TicketsUsps>
      <div className="tickets-extras__seating">
        {category?.content?.general?.gallery && category?.content?.general?.gallery?.length > 0 ? (
          <>
            <h4 className="tickets-extras__heading">{translate(translations, language, "VIEW")}</h4>
            <div className="tickets-extras__sight">
              {category?.content?.general?.gallery?.length > 1 ? (
                <Carousel
                  slidesToShow={1}
                  renderBottomCenterControls={({ currentSlide, goToSlide }) =>
                    getBottomControls(category?.content?.general?.gallery!, currentSlide, goToSlide)
                  }
                  defaultControlsConfig={{
                    nextButtonClassName: "carousel__control carousel__control--next",
                    nextButtonText: " ",
                    prevButtonClassName: "carousel__control carousel__control--prev",
                    prevButtonText: " ",
                  }}
                >
                  {category?.content?.general?.gallery.map((image, i) => (
                    <img key={i} src={addWidthParameter(parse(image?.url), 480)} alt={parse(image?.altText)} />
                  ))}
                </Carousel>
              ) : (
                <img
                  src={addWidthParameter(parse(first(category?.content?.general?.gallery)?.url), 480)}
                  className="tickets-extras__image"
                  alt={parse(first(category?.content?.general?.gallery)?.altText)}
                />
              )}
            </div>
          </>
        ) : (
          <>
            {venue?.content?.general?.title && (venue?.content?.general?.gallery || venue?.content?.general?.image) && (
              <>
                <h4 className="tickets-extras__heading">
                  {translate(translations, language, "STADIUM")}&nbsp;{venue.content.general.title}
                </h4>
                <div className="tickets-extras__sight">
                  {venue.content?.general?.gallery && venue.content?.general?.gallery.length > 1 ? (
                    <Carousel
                      slidesToShow={1}
                      renderBottomCenterControls={({ currentSlide, goToSlide }) =>
                        getBottomControls(venue?.content?.general?.gallery!, currentSlide, goToSlide)
                      }
                      defaultControlsConfig={{
                        nextButtonClassName: "carousel__control carousel__control--next",
                        nextButtonText: " ",
                        prevButtonClassName: "carousel__control carousel__control--prev",
                        prevButtonText: " ",
                      }}
                    >
                      {venue.content?.general?.gallery.map((image, i) => (
                        <img key={i} src={addWidthParameter(parse(image?.url), 180)} alt={parse(image?.altText)} />
                      ))}
                    </Carousel>
                  ) : (
                    <img
                      className="tickets-extras__image"
                      src={addWidthParameter(
                        venue?.content?.general?.gallery && first(venue.content.general.gallery)?.url
                          ? parse(first(venue?.content?.general?.gallery)?.url)
                          : parse(venue.content.general.image?.url),
                        480
                      )}
                      alt={
                        venue?.content?.general?.gallery && first(venue.content.general.gallery)?.altText
                          ? parse(first(venue?.content?.general?.gallery)?.altText)
                          : parse(venue.content.general.image?.altText)
                      }
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {extras && extras.length > 0 && (
        <div className="tickets-extras__extras">
          <h4 className="tickets-extras__heading">{translate(translations, language, "EXTRAS")}</h4>
          <div className="tickets-extras__checkbox-list">
            <div className="checkbox-list checkbox-list--toggles">
              {extras.map((extra, i) => (
                <label key={i} className={`checkbox ${extra.isSelected ? "checkbox--active" : ""}`}>
                  <input type="checkbox" className="checkbox__input" defaultChecked={extra.isSelected} onChange={(e) => selectExtras(extra)} />
                  <span className="checkbox__text">
                    {extra.line.productName}
                    {findExcursionDescription(extra.line.productCode) && (
                      <>
                        <div className={`tooltip-modal ${controlledVisible ? "tooltip-modal--active" : ""}`}>
                          <div
                            className="tooltip-modal__trigger"
                            ref={setTriggerRef}
                            onMouseOver={() => handleTooltipTrigger(extra.line.productCode)}
                          >
                            <Icon name="tooltip-white" />
                          </div>
                          {visible && hovered == extra.line.productCode && (
                            <div className="tooltip-modal__frame" ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-modal__frame" })}>
                              <div {...getArrowProps({ className: "tooltip-arrow" })} />

                              <div className="tooltip-modal__header">
                                <h3 className="tooltip-modal__heading">{extra.line.productName}</h3>
                                <div className="tooltip-modal__actions">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setHovered("");
                                      setControlledVisible(false);
                                    }}
                                    className="tooltip-modal__close-button"
                                  ></button>
                                </div>
                              </div>
                              <div
                                className="tooltip-modal__body"
                                dangerouslySetInnerHTML={{ __html: findExcursionDescription(extra.line.productCode) ?? "" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    <div className="pricing pricing--increase">+ &euro; &nbsp;{(extra.line.price / numberOfPax).toFixed(2)} p.p.</div>
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketsExtras;
