import React, { useContext, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import BookingContext, { BookingContextType } from "../../contexts/booking-provider";
import AccommodationsCards from "./accommodations-cards";
import AccommodationsMap from "./accommodations-map";
import { find } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import { TideItemForEvenementLocatie, TideItemForFacility } from "../../types";
import Icon from "../icon";
import { ServiceType } from "@qite/tide-client";

interface AccommodationsProps {
  venue?: TideItemForEvenementLocatie;
}

const Accommodations: React.FC<AccommodationsProps> = ({ venue }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const { bookingSearch, bookingPackage, hotelsLoaded, shouldCheckFlights, shouldCheckHotels } = useContext<BookingContextType>(BookingContext);

  const [useMapView, setUseMapView] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("priceAscending");

  const changeSelectedSortOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = find(event.target.options, "selected")?.value;
    if (selectedValue) {
      setSelectedSortOption(selectedValue);
    }
  };

  const queryResult = useStaticQuery(graphql`
    query facilityDataQuery {
      allTideItemForFacility {
        nodes {
          language
          content {
            general {
              title
              iconFontAwesome
              hotelbedscode
            }
          }
        }
      }
    }
  `);

  const option = bookingPackage?.options.find((o) => o.isSelected);
  return (
    <div className="accommodations">
      {!hotelsLoaded || shouldCheckFlights || shouldCheckHotels ? (
        <div className="preloader-spinner preloader-spinner--vertical-placement">
          <div className="preloader__icon">
            <Icon name="spinner" />
          </div>
          <div className="preloader__label">
            {bookingSearch?.includedServiceTypes?.includes(ServiceType.flight) ? (
              <>Vluchten worden gecontroleerd met de actuele prijzen en hotels worden geladen.</>
            ) : (
              <>Hotels worden geladen.</>
            )}
            <div className="preloader__dots">
              <div className="preloader__dot-1">.</div>
              <div className="preloader__dot-2">.</div>
              <div className="preloader__dot-3">.</div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="accommodations__header">
            <div className="booking__intro">
              <div className="heading-seperator">
                <h1 className="booking__region-heading">{translate(translations, language, "HOTELS_FOUND", { number: option?.hotels.length })}</h1>
              </div>
              <div className="booking__intro__accommodations accommodations__actions">
                <div className="accommodations__view-toggle">
                  <label className="slider">
                    <span className="slider__text">{translate(translations, language, "MAP_VIEW")}:</span>
                    <input type="checkbox" className="slider__input" checked={useMapView} onChange={() => setUseMapView(!useMapView)} />
                  </label>
                </div>

                {!useMapView && (
                  <div className="select-themed">
                    <span className="select-themed__label">{translate(translations, language, "SORT_BY")}:</span>
                    <div className="select-themed__select">
                      <select value={selectedSortOption} onChange={(event) => changeSelectedSortOption(event)}>
                        <option value="rating">{translate(translations, language, "REVIEW")}</option>
                        <option value="stars">{translate(translations, language, "STARS")}</option>
                        <option value="travelDurationToVenue">{translate(translations, language, "DISTANCE_VENUE")}</option>
                        <option value="travelDurationToCenter">{translate(translations, language, "DISTANCE_CENTER")}</option>
                        <option value="priceAscending">{translate(translations, language, "PRICE_ASCENDING")}</option>
                        <option value="priceDescending">{translate(translations, language, "PRICE_DESCENDING")}</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>


          {!useMapView ? (
            <AccommodationsCards
              selectedSortOption={selectedSortOption}
              facilities={queryResult.allTideItemForFacility.nodes as TideItemForFacility[]}
              venue={venue}
            />
          ) : (
            <AccommodationsMap facilities={queryResult.allTideItemForFacility.nodes as TideItemForFacility[]} venue={venue} />
          )}
        </>
      )}
    </div>
  );
};

export default Accommodations;
