import React, { useContext } from "react";
import BookingContext, { BookingContextType } from "../../../contexts/booking-provider";
import { TideItemForEvenementLocatie, TideItemForFacility } from "../../../types";
import { orderBy } from "lodash";
import AccommodationCard from "./card";

interface AccommodationsCardsProps {
  selectedSortOption: string;
  facilities: TideItemForFacility[];
  venue?: TideItemForEvenementLocatie;
}

const AccommodationsCards: React.FC<AccommodationsCardsProps> = ({ selectedSortOption, facilities, venue }) => {
  const { bookingPackage } = useContext<BookingContextType>(BookingContext);

  const option = bookingPackage?.options.find((o) => o.isSelected);

  return (
    <>
      {option && (
        <div className="accommodations__cards">
          {orderBy(
            option.hotels,
            [selectedSortOption == "priceAscending" || selectedSortOption == "priceDescending" ? "cheapestPrice" : selectedSortOption],
            [selectedSortOption == "rating" || selectedSortOption == "stars" || selectedSortOption == "priceDescending" ? "desc" : "asc"]
          ).map((hotel, i) => (
            <AccommodationCard key={i} hotel={hotel} facilities={facilities} venue={venue} />
          ))}
        </div>
      )}
    </>
  );
};

export default AccommodationsCards;
